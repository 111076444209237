import React, { useMemo } from 'react';
import { CARDS, numberToColumn } from '../common/game';
import { classNames, iff } from '../common/reactTools';

const CW = 111, CH = 166, TW = 101, TS = 90, TB = 118, FP = 2;

export default function Card({ cardNumber, selected, onSelect }) {
    const numbers = useMemo(() => CARDS[cardNumber], [cardNumber]);

    return (
        <svg className="card" viewBox="-10 -10 1010 516">
            {numbers.map((nums, row) => {
                let selectedOnRow = 0;

                return (
                    <g key={row} className="card-row">
                        {nums.reduce((res, n) => {
                            res[numberToColumn(n)] = n;

                            return res;
                        }, Array(9).fill(0)).map((n, col) => {
                            const isSelected = selected.indexOf(n) !== -1;

                            if (isSelected) {
                                selectedOnRow += 1;
                            }

                            return (
                                <g key={col}
                                    {...classNames('card-cell', 'card-cell-' + (n ? 'number' : 'empty'), ['card-cell-selected', isSelected])}>
                                    <rect
                                        className="card-cell-border"
                                        onClick={() => n && onSelect({ number: n })}
                                        width={CW}
                                        height={CH}
                                        x={col * CW}
                                        y={row * CH} />
                                    {iff(n, (
                                        <text
                                            className="card-cell-text"
                                            onClick={() => n && onSelect({ number: n })}
                                            x={(col + 0.5) * CW}
                                            y={row * CH + TB}
                                            text-anchor="middle"
                                            font-size={TS}
                                            textLength={TW}>{n}</text>
                                    ), (
                                        <rect key={col}
                                            className="card-cell-filler"
                                            onClick={() => n && onSelect({ number: n })}
                                            width={CW - FP * 2}
                                            height={CH - FP * 2}
                                            x={col * CW + FP}
                                            y={row * CH + FP} />
                                    ))}
                                </g>
                            );
                        })}
                        {/*
                                        width={CW}
                                        height={CH}
                                        x={col * CW}
                                        y={row * CH}
                        */}
                        {iff(selectedOnRow === 5, (
                            <path d={`M ${CW / 10} ${(row + 0.5) * CH} Q 250 ${(row + 0.5 - 0.2) * CH}, 500 ${(row + 0.5 + 0.1) * CH} T ${1000 - CW / 10} ${(row + 0.5) * CH}`} stroke="blue"
                            stroke-width="5" fill="none" />
                        ))}
                    </g>
                );
            })}
            <g className="card-number">
                <text
                    className="card-number-text"
                    x={12}
                    y={16}
                    text-anchor="middle"
                    font-size={14}>{cardNumber}</text>
            </g>
        </svg>
    );
}
