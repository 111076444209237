import React from 'react';

export function ControlPanel({ children }) {
    return (
        <div className="control-panel">
            {children}
        </div>
    );
}

export function ControlPanelSection({ label, children, showWhen = true }) {
    return showWhen && (
        <div className="control-panel-section">
            <strong>{label}</strong>
            {children}
        </div>
    );
}
