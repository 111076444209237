import { useEffect, useState } from "react";

/**
 * 
 * @param keyDownCallback {Function}
 */
export function useKeyboard(keyDownCallback) {
  const [lastKey, setLastKey] = useState('none');

  useEffect(() => {
    function keyDown(e) {
      const log = `key='${e.key}' | code='${e.code}' | ${new Date().toLocaleTimeString()}`;

      console.log(log);
      setLastKey(log);

      keyDownCallback(e);
    }

    window.addEventListener('keydown', keyDown, true);
    console.log('addEventListener');

    return () => {
      console.log('removeEventListener');
      return window.removeEventListener('keydown', keyDown, true);
    };
  }, [keyDownCallback]);

  return { lastKey };
}
