export function iff(condition, component1, component2 = null) {
    if (condition) {
        return component1;
    } else if (component2) {
        return component2;
    }
}

export function classNames(...conditionalClasses) {
    const conditionalClassesParsed = [].concat(...conditionalClasses
            .map(classes => {
                switch (typeof classes) {
                    case 'string':
                        return [[classes, true]];
                    case 'object':
                        if (Array.isArray(classes)) {
                            return [classes];
                        } else if (classes) {
                            return Object.entries(classes);
                        }
                        break;
                    default:
                        break;
                }

                return [];
            }));

    return {
        className: conditionalClassesParsed
            .filter(([, condition]) => condition)
            .map(([className]) => className)
            // .filter((value, index, self) => self.indexOf(value) === index) // Removing duplicates
            .join(' '),
    };
}
