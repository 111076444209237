import { useMemo, useState, useEffect } from "react";

export function CreateSystemSpeaker(voiceVariant) {
    const msg = new SpeechSynthesisUtterance();

    msg.voice = voiceVariant.voice;

    return text => {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel();
        }

        msg.text = text;
        window.speechSynthesis.speak(msg);
    };
}

function useSystemVoices() {
    const [voices, setVoices] = useState();

    useEffect(() => {
        const synth = window.speechSynthesis;
        const id = setInterval(() => {
            if (synth.getVoices().length === 0) {
                return;
            }

            setVoices(v => ({
                name: 'System',
                variants: synth.getVoices().map(voice => (
                    {
                        name: voice.name,
                        voice,
                    })),
                speakerFactory: CreateSystemSpeaker
            }));

            clearInterval(id);
        }, 10);
    }, []);

    return voices;
}

const silence = {
    name: 'Mute',
    variants: [
        {
            name: 'Silence',
        }
    ],
    speakerFactory: (variant) => text => void (variant + text)
};

export function useVoices() {
    const [voices, setVoices] = useState([silence]);
    const systemVoices = useSystemVoices();

    useEffect(() => {
        if (systemVoices) {
            setVoices(vs => [...vs, systemVoices]);
        }
    }, [systemVoices]);

    return voices;
}

export function useSpeaker(voice, voiceVariant) {
    return useMemo(() => voice.speakerFactory(voiceVariant), [voice, voiceVariant]);
}
