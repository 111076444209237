import './ShortcutsHint.css';
import React, { useState } from 'react';
import { classNames } from '../common/reactTools';

export default function ShortcutsHint({ shortcuts, ...props }) {
  const [isOpen, setIsOpen] = useState(true);

  return <div {...classNames('shorctut-hints', { 'shorctut-hints-popup-open': isOpen })} {...props}>
    <div className="shorctut-hints-popup">
      <table>
        <tbody>
          {shortcuts.map(({ keys, action }, index) => (<tr key={index}>
            <td className="shorctut-hints-keys">{keys.map(key => <b key={key}>{key}</b>)}</td>
            <td className="shorctut-hints-action">{action}</td>
          </tr>))}
        </tbody>
      </table>
    </div>
    <button className="shorctut-hints-button"
      onClick={() => setIsOpen(v => !v)}>{isOpen ? 'X' : '?'}</button>
  </div>;
}
