import React, { useEffect, useMemo, useState } from 'react';
import './PageCards.css';
import { randCardNumbers } from '../common/game';
import { ControlPanel, ControlPanelSection } from '../components/ControlPanel';
import Card from '../components/Card';
import { classNames } from '../common/reactTools';

export default function PageCards() {
    const [count, setCount] = useState(2)
    const [cards, setCards] = useState(randCardNumbers(4));
    const [selected, setSelected] = useState([]);
    const [columns, setColumns] = useState(1);

    const columnCounts = useMemo(() => [
        [], [1], [1, 2], [1, 2, 3], [1, 2, 4]
    ][count], [count]);

    function onRandomizeClick() {
        setCards(randCardNumbers(4));
        //setSelected([]);
    }

    function onSelect({ number }) {
        if (selected.indexOf(number) === -1) {
            setSelected(s => [...s, number]);
        } else {
            setSelected(s => s.filter(n => n !== number));
        }
    }

    function onReset() {
        setSelected([]);
    }

    useEffect(() => {
        setColumns(c => columnCounts.indexOf(columns) === -1 ? 1 : c);
    }, [columnCounts, columns]);

    return (
        <div className="page-cards">
            <ControlPanel>
                <ControlPanelSection label="Cards">
                    <label>({cards.join(', ')})</label>
                    <button onClick={onRandomizeClick}>Randomize</button>
                    <button onClick={onReset}>Reset</button>
                </ControlPanelSection>
                <ControlPanelSection label="Count">
                    {[1, 2, 3, 4].map(i => (<button key={i} onClick={() => setCount(i)} {...classNames({ 'active': count === i })}>{i}</button>))}
                </ControlPanelSection>
                <ControlPanelSection label="Columns">
                    {columnCounts.map(i => (<button key={i} onClick={() => setColumns(i)} {...classNames({ 'active': columns === i })}>{i}</button>))}
                </ControlPanelSection>
            </ControlPanel>

            <div className={'cards-container cards-container-' + columns}>
                {cards.slice(0, count).map((n, i) => (
                    <div className="card-wrapper">
                        <Card
                            key={`${n}-${i}`}
                            cardNumber={n}
                            selected={selected}
                            onSelect={onSelect} />
                    </div>
                ))}
            </div>
        </div>
    );
}
