export function rand() {
    const base = [];

    for (let i = 0; i < 90; i++) {
        base[i] = {
            rnd: Math.random(),
            num: i + 1,
        }
    }

    base.sort((a, b) => a.rnd - b.rnd);

    const res = base.map(a => a.num);

    console.log('Numbers.List', res);

    return res;
}

export const CARDS = {
    1: [
        [1, 23, 44, 61, 80],
        [16, 36, 59, 68, 89],
        [28, 49, 58, 71, 84]
    ],
    2: [
        [2, 24, 39, 56, 73],
        [13, 31, 48, 67, 70],
        [7, 19, 46, 51, 87]
    ],
    3: [
        [3, 20, 38, 79, 86],
        [10, 33, 41, 65, 77],
        [18, 29, 43, 50, 83]
    ],
    4: [
        [4, 17, 32, 66, 82],
        [14, 27, 40, 53, 74],
        [8, 37, 60, 78, 85]
    ],
    5: [
        [5, 21, 47, 63, 81],
        [11, 25, 35, 52, 75],
        [15, 30, 55, 62, 72]
    ],
    6: [
        [6, 26, 43, 57, 76],
        [12, 34, 54, 64, 88],
        [9, 22, 42, 69, 90]
    ],
    7: [
        [7, 31, 40, 69, 79],
        [4, 23, 37, 58, 74],
        [13, 25, 47, 53, 87]
    ],
    8: [
        [8, 22, 67, 72, 83],
        [18, 33, 49, 61, 73],
        [1, 17, 35, 50, 85]
    ],
    9: [
        [9, 20, 36, 70, 84],
        [16, 46, 56, 71, 86],
        [2, 27, 38, 51, 66]
    ],
    10: [
        [10, 29, 52, 68, 82],
        [5, 32, 41, 65, 80],
        [19, 28, 44, 78, 81]
    ],
    12: [
        [12, 39, 43, 59, 75],
        [6, 42, 55, 60, 89],
        [15, 26, 34, 64, 76]
    ],
    13: [
        [13, 29, 43, 61, 75],
        [3, 24, 48, 65, 82],
        [9, 36, 45, 51, 78]
    ],
    14: [
        [14, 30, 40, 55, 89],
        [4, 10, 34, 44, 50],
        [49, 58, 69, 79, 88]
    ],
    15: [
        [15, 27, 41, 54, 63],
        [6, 11, 37, 46, 62],
        [1, 22, 52, 70, 87]
    ],
    16: [
        [16, 28, 38, 66, 85],
        [2, 12, 23, 31, 56],
        [7, 39, 68, 74, 80]
    ],
    18: [
        [18, 25, 57, 71, 90],
        [8, 19, 32, 64, 83],
        [21, 35, 42, 59, 76]
    ],
    19: [
        [19, 33, 64, 75, 80],
        [3, 12, 25, 54, 60],
        [28, 37, 42, 78, 86]
    ],
    21: [
        [21, 36, 49, 73, 88],
        [8, 16, 44, 68, 76],
        [14, 29, 52, 69, 85]
    ],
    23: [
        [23, 48, 53, 71, 87],
        [2, 13, 35, 41, 89],
        [5, 15, 30, 55, 74]
    ],
    24: [
        [24, 34, 59, 65, 81],
        [7, 18, 26, 58, 79],
        [4, 11, 39, 46, 67]
    ],
    25: [
        [25, 47, 67, 73, 90],
        [5, 20, 53, 73, 81],
        [32, 59, 60, 77, 84]
    ],
    26: [
        [26, 42, 57, 71, 86],
        [8, 19, 33, 64, 83],
        [10, 21, 35, 51, 76]
    ],
    28: [
        [28, 36, 66, 74, 82],
        [2, 12, 24, 31, 56],
        [7, 17, 39, 68, 80]
    ],
    29: [
        [29, 43, 61, 75, 85],
        [3, 13, 23, 48, 65],
        [9, 16, 38, 45, 78]
    ],
    30: [
        [30, 41, 55, 79, 89],
        [4, 18, 34, 44, 52],
        [15, 49, 50, 69, 87]
    ],
    31: [
        [31, 46, 66, 75, 83],
        [6, 10, 22, 40, 63],
        [19, 38, 56, 77, 86]
    ],
    32: [
        [32, 50, 61, 72, 84],
        [1, 20, 45, 57, 90],
        [9, 23, 43, 51, 62]
    ],
    35: [
        [35, 48, 53, 71, 87],
        [2, 13, 27, 41, 89],
        [5, 15, 30, 55, 74]
    ],
    36: [
        [36, 49, 68, 73, 88],
        [8, 11, 25, 44, 79],
        [14, 29, 52, 65, 85]
    ],
    37: [
        [37, 49, 65, 77, 81],
        [5, 13, 26, 48, 78],
        [1, 28, 33, 59, 67]
    ],
    38: [
        [38, 45, 69, 75, 89],
        [14, 25, 47, 57, 62],
        [7, 10, 36, 79, 84]
    ],
    39: [
        [39, 52, 68, 72, 82],
        [18, 23, 30, 63, 76],
        [9, 12, 27, 51, 86]
    ],
    40: [
        [40, 53, 61, 74, 83],
        [15, 21, 32, 46, 58],
        [2, 11, 29, 44, 80]
    ],
    41: [
        [41, 54, 60, 71, 90],
        [8, 16, 35, 43, 64],
        [3, 22, 31, 56, 73]
    ],
    43: [
        [43, 50, 62, 73, 89],
        [11, 21, 37, 61, 84],
        [5, 18, 30, 42, 70]
    ],
    45: [
        [45, 54, 63, 75, 90],
        [3, 19, 31, 67, 82],
        [10, 28, 40, 55, 77]
    ],
    46: [
        [46, 52, 60, 78, 80],
        [7, 13, 27, 34, 72],
        [2, 17, 22, 36, 81]
    ],
    47: [
        [47, 56, 65, 71, 85],
        [4, 20, 33, 68, 88],
        [9, 12, 26, 38, 59]
    ],
};

export const CARD_NUMBERS = Object.keys(CARDS).map(n => parseInt(n));

export function randCardNumbers(count = 1) {
    const indexes = [];

    while (indexes.length < count) {
        const index = Math.floor(Math.random() * CARD_NUMBERS.length);

        if (indexes.indexOf(index) === -1) {
            indexes.push(index);
        }
    }

    return indexes.sort((a, b) => a - b).map(i => CARD_NUMBERS[i]);
}

/**
 * column number in 0..8 range
 * @param {number} num
 * @returns {number} column number in 0..8 range
 */
export function numberToColumn(num) {
    return num === 90 ? 8 : Math.floor(num / 10);
}

export function getAllNumbers() {
    const l1 = [];

    for (let x = 0; x < 9; x++) {
        const l2 = [];

        for (let y = 0; y < 10; y++) {
            if (x === 0 && y === 0) {
                continue;
            }

            l2.push(x * 10 + y);
        }

        if (x === 8) {
            l2.push(90);
        }

        l1.push(l2);
    }

    return l1;
}