import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './PageGame.css';
import { iff } from '../common/reactTools';
import { openFullscreen, fullscreenEnabled } from '../common/system';
import { rand, getAllNumbers } from '../common/game';
import { useSpeaker, useVoices } from '../common/voice';
import ShortcutsHint from '../components/ShortcutsHint';
import { useKeyboard } from '../common/useKeyboard';

export const NUMBERS_COUNT = 90;
export const MODE_INITIAL = 'MODE_INITIAL';
export const MODE_PLAYING = 'MODE_PLAYING';
export const MODE_PAUSED = 'MODE_PAUSED';
export const MODE_FINISHING = 'MODE_FINISHING';
export const MODE_FINISHED = 'MODE_FINISHED';
export const INTERVALS = [2000, 4000, 6000, 8000];
export const INTERVAL_DEFAULT = 4000;
export const INTERVAL_FINISHING = 100;
export const INTERVAL_NEVER = 0;

const shortcuts = [
  { keys: ['Space'], action: 'Play / Pause' },
  { keys: ['N'], action: 'New Game' },
  { keys: ['F'], action: 'Finish' },
  { keys: INTERVALS.map(i => (i / 1000).toString()), action: 'Interval' },
];

function Game() {
  const allNumbers = useMemo(getAllNumbers, []);
  const voices = useVoices();

  /* State --------------------------- */
  const [mode, setMode] = useState(MODE_INITIAL);
  const [game, setGame] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [index, setIndex] = useState(0);
  const [interval, setInterval] = useState(INTERVAL_DEFAULT);
  const [timeoutHandler, setTimeoutHandler] = useState();
  const [voice, setVoice] = useState(voices[0]);
  const [voiceVariant, setVoiceVariant] = useState(voice.variants[0]);

  const speaker = useSpeaker(voice, voiceVariant);

  /* Actions --------------------------- */
  const actionSetInterval = useCallback(function (newInterval) {
    setInterval(newInterval);

    if (newInterval === INTERVAL_NEVER) {
      setMode(MODE_PAUSED);
    } else if (mode === MODE_PAUSED) {
      setMode(MODE_PLAYING);
    }
  }, [mode]);

  const actionNewGame = useCallback(function () {
    setGame(g => g + 1);
    setIndex(0);
    setNumbers(rand());
    setMode(MODE_PLAYING);
  }, []);

  const actionFinishGame = useCallback(function () {
    setMode(m => m === MODE_PAUSED || m === MODE_PLAYING ? MODE_FINISHING : m);
  }, []);

  const actionPlayPause = useCallback(function () {
    setMode(m => {
      switch (m) {
        case MODE_PAUSED:
          return MODE_PLAYING;
        case MODE_PLAYING:
          return MODE_PAUSED;
        default:
          return m;
      }
    });
  }, []);

  /* Event listeners --------------------------- */
  function clickInterval(int) { actionSetInterval(int); }

  function clickNewGame() { actionNewGame(); }

  function clickFinish() { actionFinishGame(); }

  function clickFullscreen() { openFullscreen(); }

  function clickPause() { actionPlayPause(); }

  function clickVoice(selectedVoice, selectedVariant) {
    setVoice(selectedVoice);
    setVoiceVariant(selectedVariant);
  }

  const keyDownCallback = useCallback(e => {
    console.log(e);
    switch (e.code) {
      case 'MediaPlayPause':
      case 'Space':
      case 'KeyP':
        e.preventDefault();
        actionPlayPause();
        break;
      case 'KeyN':
      case 'KeyG':
        actionNewGame();
        break;
      case 'KeyF':
        actionFinishGame();
        break;
      default:
        if (e.key >= '0' && e.key <= '9') {
          const num = e.key * 1000;

          if (INTERVALS.indexOf(num) !== -1) {
            setInterval(num);
          }
        }
        break;
    }
  }, [actionFinishGame, actionNewGame, actionPlayPause]);

  const { lastKey } = useKeyboard(keyDownCallback);

  /* Effect handlers --------------------------- */

  useEffect(() => {
    setTimeoutHandler(handler => clearTimeout(handler));

    if (interval === 0
      || index === NUMBERS_COUNT
      || (mode !== MODE_PLAYING && mode !== MODE_FINISHING)) {
      return;
    }

    setTimeoutHandler(setTimeout(() => {
      setIndex(i => {
        if (i === NUMBERS_COUNT - 1) {
          setMode(MODE_FINISHED);
        }

        return i + 1;
      });
    }, mode === MODE_FINISHING ? INTERVAL_FINISHING : interval));

    return () => setTimeoutHandler(handler => clearTimeout(handler));
  }, [index, interval, mode]);

  useEffect(() => {
  }, [mode]);

  useEffect(() => {
    if (mode === MODE_PLAYING) {
      speaker(`${numbers[index]}`);
    }
  }, [index, mode, numbers, speaker]);

  const selectedNumbers = numbers.slice(0, index + 1);

  return (
    <div className="page-game">
      <div className="control-panel">
        <div className="control-panel-section">
          <strong>Game</strong>
          <button onClick={clickNewGame} tabIndex="-1">New game</button>
        </div>

        <div className="control-panel-section">
          <strong>Speed</strong>
          <button
            onClick={clickPause.bind(this)}
            className={`${mode === MODE_PLAYING ? 'paused' : 'playing'}`}
            disabled={!(mode === MODE_PAUSED || mode === MODE_PLAYING)}>
            {mode === MODE_PLAYING ? 'Pause' : 'Play'}
          </button>
          {INTERVALS.map(s => (
            <button key={s}
              onClick={clickInterval.bind(this, s)}
              className={s === interval ? 'active' : ''}>
              {`${s / 1000}s`}
            </button>
          ))}
        </div>

        {iff(mode === MODE_PAUSED || mode === MODE_PLAYING, (<div className="control-panel-section">
          <strong>Finish</strong>
          <button onClick={clickFinish}>Finish</button>
        </div>))}

        {iff(fullscreenEnabled, (<div className="control-panel-section">
          <strong>View</strong>
          <button onClick={clickFullscreen}>Full screen</button>
        </div>))}

        <div className="control-panel-section">
          <strong>Speaker</strong>
          {voices.map(v => <button
            key={v.name}
            className={`button-dropdown ${v === voice ? 'active' : ''}`}>
            {v.name} {v === voice ? `(${voiceVariant.name})` : `[${v.variants.length}]`}

            <ul className="button-dropdown-list">
              {v.variants.map(r => <li
                key={r.name}
                onClick={() => clickVoice(v, r)}
                className={`button-dropdown-list-item ${r === voiceVariant ? 'active' : ''}`}>
                {r.name}
              </li>)}
            </ul>
          </button>)}
        </div>
      </div>

      <div className="game-state">
        <ShortcutsHint shortcuts={shortcuts} />
        <div className={`current-number current-number-${index % 2 === 0 ? 'even' : 'odd'}`}>
          <div className="current-number-ball">
            <div className="current-number-label current-number-label-even">
              <span>{numbers.length === 0 ? 'O' : index === 90 ? 'fin' : index === 90 ? 'fin' : numbers[index % 2 === 0 ? index : index - 1]}</span>
            </div>
            <div className="current-number-label current-number-label-odd">
              <span>{numbers[index % 2 !== 0 ? index : index - 1]}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="history-numbers-container">
        <div className="history-numbers">
          {selectedNumbers.map((n, i) => (
            <div
              className={'history-number' + (n === numbers[index]
                ? ' history-number-current'
                : n === numbers[index - 1]
                  ? ' history-number-previous' : '')}
              key={i}>{n}</div>
          ))}
        </div>
      </div>

      <div className="all-numbers">
        {allNumbers.map(col => (
          <div key={col} className="all-numbers-column">
            {col.map((n) => (
              <div
                className={'all-numbers-cell' + (n === numbers[index]
                  ? ' all-numbers-cell-current'
                  : n === numbers[index - 1]
                    ? ' all-numbers-cell-previous' : '') + (selectedNumbers.indexOf(n) === -1 ? '' : ' all-numbers-cell-selected')}
                key={n}>{n}</div>
            ))}
          </div>
        ))}
      </div>

      <pre>
        Mode: {mode}
        |
        Game: {game}
        |
        Step: {index}
        |
        Key: {lastKey}
        |
        Interval: {interval}
        |
        Timeout Enabled: {timeoutHandler ? 'Yes' : 'No'}
      </pre>
    </div>
  );
}

export default Game;
