import React, { createContext, useState } from 'react';
import './App.css';
import { iff } from '../common/reactTools';
import PageCards from './PageCards';
import PageGame from './PageGame';

export const PAGE_MENU = 'menu';
export const PAGE_GAME = 'game';
export const PAGE_CARD = 'card';

export const RouteContext = createContext(PAGE_MENU);

function App() {
  const [page, setPage] = useState((window.location.hash || '#').substring(1) || PAGE_MENU);

  return (
    <RouteContext.Provider value={page}>
      <div className="App">
        {iff(page === PAGE_MENU, (
          <div className="main-menu">
            <button onClick={() => setPage(PAGE_GAME)}>Game</button>
            <button onClick={() => setPage(PAGE_CARD)}>Card <sub>beta</sub></button>
          </div>
        ))}

        {iff(page === PAGE_GAME, (
          <PageGame />
        ))}

        {iff(page === PAGE_CARD, (
          <PageCards />
        ))}
      </div>
    </RouteContext.Provider>
  );
}

export default App;
